import { smoothScroll } from 'utils/smoothScroll.js'

document.addEventListener('DOMContentLoaded', function () {
  const pageSelector = '.p-group-session-categories'
  if (document.querySelector(pageSelector) === null) return

  const scrollBtn = document.querySelector(`${pageSelector}__btn-scroll--js`)
  if (scrollBtn !== null) {
    scrollBtn.addEventListener('click', function () {
      smoothScroll(`${pageSelector}__sessions-header`)
    })
  }
})
