import Cookies from 'universal-cookie'
import getValidatedRecaptcha from 'components/recaptcha-widget/recaptcha-widget.js'
import { emailConfirmShowModal } from 'components/email-confirm-modal/email-confirm-modal.js'

const delayResubmitTime = 0.5 * 1000 /* half a second */
const cookies = new Cookies()
export const noTrackHideEvent = 'noTrackHideEvent'

// Call this to initialize the validation handlers and set up
// the form actions
const emailListformSetUp = (container, handleSuccess, handleFailure) => {
  const form = container.querySelector('form')
  form.addEventListener('submit', function (event) {
    event.preventDefault()
    handleFormSubmit(container, handleSuccess, handleFailure, false)
  })
}

// First entrypoint when user taps submit. Check recaptcha token,
// then check for birthdate validation.
const handleFormSubmit = (container, handleSuccess, handleFailure, allowNilBirthdatePost) => {
  const form = container.querySelector('form')

  // Bail if recaptcha missing
  const recaptcha_token = getValidatedRecaptcha()
  if (!recaptcha_token) {
    enableResubmit(container)
    return false
  }

  // Finally, post the form
  doFormPost(container, handleSuccess, handleFailure, recaptcha_token)
}

const doFormPost = (container, handleSuccess, handleFailure, recaptcha_token) => {
  const form = container.querySelector('form')
  fetch(`${form.action}.json`, {
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    method: 'POST',
    body: JSON.stringify(formData(form, recaptcha_token)),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.registerEmail) cookies.set('promoEmailRegistered', true, { path: '/' })
      if (data.success) {
        handleSuccess(data, container)
      } else handleFailure(data, container)
    })
}

const enableResubmit = (container) => {
  setTimeout(() => {
    const submissionBtn = container.querySelector('.btn-cta')
    submissionBtn.removeAttribute('disabled')
  }, delayResubmitTime)
}

const formData = (form, recaptcha_token) => {
  return {
    authenticity_token: form.querySelector("[name='authenticity_token']").value,
    email_list: {
      sign_up_url: form.querySelector("[name='email_list[sign_up_url]']").value,
      label: form.querySelector("[name='email_list[label]']").value,
      email: form.querySelector("[name='email_list[email]']").value,
    },
    recaptcha_response: recaptcha_token,
  }
}

export const hideNewsletterCallbacks = (container) => {
  if (!container.classList.contains(noTrackHideEvent)) {
    hideNewsletterForDay()
  }
}

const hideNewsletterForDay = () => {
  let expiration = new Date()
  expiration.setDate(expiration.getDate() + 1)
  cookies.set('hideNewsletterModal', true, { path: '/', expires: expiration })
}

export default emailListformSetUp
