// relies on jQuery
import intlTelInput from 'intl-tel-input'

$(function () {
  if ($('.users.edit #user_phone_number').length) {
    let iti = intlTelInput(document.querySelector('#user_phone_number'), {
      initialCountry: $('#user_phone_number').data('default-country'),
      formatOnDisplay: true,
      separateDialCode: true,
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.8/js/utils.js',
    })

    $('#user_phone_number')
      .parents('form')
      .submit(function () {
        if ($('#user_phone_number').val().length) {
          $('#user_phone_number').val(iti.getNumber())
        }
      })
  }
})

$(function () {
  if ($('.profilepage .js-edit-membership').length && $('.js-edit-membership-form').length) {
    $('.profilepage .js-edit-membership').click(function () {
      $(this).next('.js-edit-membership-form').show('400')
      $(this).hide()
    })
  }
})
